import { createError } from '#app'

export default defineNuxtRouteMiddleware(async to => {
  const { $auth } = useNuxtApp()

  const { data: currentUser } = await useAsyncData(
    'currentUser',
    () => $auth.getCurrentUser(),
    { server: false }
  )

  const isUserLoggedIn = !!currentUser.value
  const isUserAuthorized =
    currentUser.value?.groups.includes('dpa:employee') ||
    currentUser.value?.groups.includes('dpa:freelancer')

  if (isUserLoggedIn && !isUserAuthorized) {
    return abortNavigation(
      createError({
        statusCode: 403,
        message:
          'Du hast nicht die erforderliche Berechtigung, um den Rubix Monitor zu sehen. Bitte wende dich an das Support-Team.'
      })
    )
  }

  if (to.path.startsWith('/login/auth-callback') && isUserLoggedIn) {
    return navigateTo('/')
  }

  if (!isUserLoggedIn) {
    if (to.path.startsWith('/login')) return
    return navigateTo('/login')
  }
})
