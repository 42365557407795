export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"icon","href":"/favicon/dev/favicon.ico"},{"rel":"manifest","href":"/favicon/dev/site.webmanifest"}],"style":[],"script":[{"innerHTML":"window.AppcuesSettings = { enableURLDetection: true }","type":"text/javascript"},{"src":"//fast.appcues.com/137377.js"}],"noscript":[],"htmlAttrs":{"lang":"de"},"title":"Rubix Monitor"}

export const appRootId = "dpa_monitor_root"

export const appRootTag = "div"

export const appBaseUrl = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnUrl = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#dpa_monitor_root'