import { defineNuxtPlugin } from '#app'
import * as vt from 'vue-toastification'
import IconCheck from '@/assets/icons/check.svg'
import 'vue-toastification/dist/index.css'

const options: vt.PluginOptions = {
  position: vt.POSITION.BOTTOM_LEFT,
  hideProgressBar: true,
  closeButton: false,
  transition: 'Vue-Toastification__fade',
  toastDefaults: {
    [vt.TYPE.DEFAULT]: {
      icon: IconCheck,
      toastClassName: 'custom-toast-default'
    }
  }
}

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(vt.default, options)
  return {
    provide: {
      toast: vt.useToast()
    }
  }
})
