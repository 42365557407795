import { default as _91urn_93jjsEUfQ6BQMeta } from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/index/content/[urn].vue?macro=true";
import { default as indexLWYZX9UqOtMeta } from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/index.vue?macro=true";
import { default as auth_45callbackRlqS4MEem3Meta } from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/login/auth-callback.vue?macro=true";
import { default as indexDaftMoxzBvMeta } from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/login/index.vue?macro=true";
import { default as indexkYhgm5C0SHMeta } from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/privacy-policy/index.vue?macro=true";
import { default as indexGWZTWO3PCKMeta } from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/settings/index.vue?macro=true";
export default [
  {
    name: indexLWYZX9UqOtMeta?.name ?? "index",
    path: indexLWYZX9UqOtMeta?.path ?? "/",
    children: [
  {
    name: _91urn_93jjsEUfQ6BQMeta?.name ?? "index-content-urn",
    path: _91urn_93jjsEUfQ6BQMeta?.path ?? "content/:urn()",
    meta: _91urn_93jjsEUfQ6BQMeta || {},
    alias: _91urn_93jjsEUfQ6BQMeta?.alias || [],
    redirect: _91urn_93jjsEUfQ6BQMeta?.redirect || undefined,
    component: () => import("/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/index/content/[urn].vue").then(m => m.default || m)
  }
],
    meta: indexLWYZX9UqOtMeta || {},
    alias: indexLWYZX9UqOtMeta?.alias || [],
    redirect: indexLWYZX9UqOtMeta?.redirect || undefined,
    component: () => import("/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: auth_45callbackRlqS4MEem3Meta?.name ?? "login-auth-callback",
    path: auth_45callbackRlqS4MEem3Meta?.path ?? "/login/auth-callback",
    meta: auth_45callbackRlqS4MEem3Meta || {},
    alias: auth_45callbackRlqS4MEem3Meta?.alias || [],
    redirect: auth_45callbackRlqS4MEem3Meta?.redirect || undefined,
    component: () => import("/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/login/auth-callback.vue").then(m => m.default || m)
  },
  {
    name: indexDaftMoxzBvMeta?.name ?? "login",
    path: indexDaftMoxzBvMeta?.path ?? "/login",
    meta: indexDaftMoxzBvMeta || {},
    alias: indexDaftMoxzBvMeta?.alias || [],
    redirect: indexDaftMoxzBvMeta?.redirect || undefined,
    component: () => import("/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexkYhgm5C0SHMeta?.name ?? "privacy-policy",
    path: indexkYhgm5C0SHMeta?.path ?? "/privacy-policy",
    meta: indexkYhgm5C0SHMeta || {},
    alias: indexkYhgm5C0SHMeta?.alias || [],
    redirect: indexkYhgm5C0SHMeta?.redirect || undefined,
    component: () => import("/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexGWZTWO3PCKMeta?.name ?? "settings",
    path: indexGWZTWO3PCKMeta?.path ?? "/settings",
    meta: indexGWZTWO3PCKMeta || {},
    alias: indexGWZTWO3PCKMeta?.alias || [],
    redirect: indexGWZTWO3PCKMeta?.redirect || undefined,
    component: () => import("/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/settings/index.vue").then(m => m.default || m)
  }
]