import { getToken } from '~/utils/auth'

export default defineNuxtPlugin(nuxtApp => {
  // https://apollo.nuxtjs.org/recipes/authentication#auth-hook
  nuxtApp.hook('apollo:auth', async ({ token }) => {
    const jwtToken = await getToken()

    if (jwtToken) {
      token.value = jwtToken
    }
  })
})
