export class OauthConfig {
  public readonly domain: string
  public readonly redirectSignIn: string
  public readonly redirectSignOut: string
  public readonly responseType = 'code'
  public readonly scope = [
    'email',
    'openid',
    'profile',
    'aws.cognito.signin.user.admin'
  ]

  constructor() {
    this.domain = import.meta.env.VITE_COGNITO_OAUTH_DOMAIN!
    this.redirectSignIn = import.meta.env.VITE_COGNITO_OAUTH_REDIRECT_SIGNIN!
    this.redirectSignOut = import.meta.env.VITE_COGNITO_OAUTH_REDIRECT_SIGNOUT!
  }
}

export class CookieStorage {
  public readonly domain = import.meta.env.VITE_AUTH_COOKIE_DOMAIN
  public readonly secure = !import.meta.env.DEV
  public readonly path = '/'
  public readonly expires = 30
  public readonly sameSite = 'lax'
}

export class AwsConfig {
  public readonly aws_project_region = 'eu-central-1'
  public readonly aws_cognito_region = 'eu-central-1'
  public readonly federationTarget = 'COGNITO_USER_POOLS'
  public readonly aws_cognito_identity_pool_id: string
  public readonly aws_user_pools_id: string
  public readonly aws_user_pools_web_client_id: string
  public readonly oauth: OauthConfig
  public readonly cookieStorage: CookieStorage

  constructor() {
    this.aws_cognito_identity_pool_id =
      import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID!
    this.aws_user_pools_id = import.meta.env.VITE_COGNITO_USER_POOL_ID!
    this.aws_user_pools_web_client_id =
      import.meta.env.VITE_COGNITO_USER_POOL_WEBCLIENT_ID!

    this.oauth = new OauthConfig()
    this.cookieStorage = new CookieStorage()
  }

  public applyHistoryHackIfRequired(window: Window): void {
    const currentRootUrl = `${window.location.protocol}//${window.location.host}`
    const redirectSignInUrl = this.oauth.redirectSignIn
    const delegate = window.history.replaceState

    if (!this.isHackRequired(currentRootUrl)) return

    window.history.replaceState = function (
      data: never,
      title: string,
      url?: string | null
    ) {
      if (url?.startsWith(redirectSignInUrl)) {
        url = `${currentRootUrl}/`
      }
      delegate.call(this, data, title, url)
    }
  }

  private isHackRequired(currentRootUrl: string): boolean {
    return !this.oauth.redirectSignIn.startsWith(currentRootUrl)
  }
}
