import { init, BrowserTracing, vueRouterInstrumentation } from '@sentry/vue'

export default defineNuxtPlugin(() => {
  const { $router, vueApp, $config } = useNuxtApp()
  const { sentry, graphlUrl } = $config.public

  if (!sentry.dsn) return

  init({
    app: vueApp,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: vueRouterInstrumentation($router, {
          routeLabel: 'path'
        })
      })
    ],
    tracePropagationTargets: ['localhost', $config.public.baseURL, graphlUrl],
    tracesSampleRate: 0.2,
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications.',
      'ResizeObserver loop limit exceeded',
      'Du hast nicht die erforderliche Berechtigung, um den Rubix Monitor zu sehen. Bitte wende dich an das Support-Team.'
    ],
    ...sentry
  })
})
