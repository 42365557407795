import { Auth } from 'aws-amplify'
import { CognitoIdToken } from 'amazon-cognito-identity-js'
import { CognitoUser } from '@aws-amplify/auth'
import { setUser as sentrySetUser } from '@sentry/vue'
import { type RubixUser } from '~/types'

export const login = async () => {
  const custState = `${window.location.host}/login/auth-callback`
  await Auth.federatedSignIn({
    customProvider: 'dpaid-auth0',
    customState: custState
  })
}

export const logout = async () => {
  await Auth.signOut()
}

export const getCurrentUser = async (): Promise<RubixUser | undefined> => {
  const payload = await getIdTokenPayload()

  if (payload) {
    const groups = payload['cognito:groups'] || []

    sentrySetUser({
      userId: payload['custom:account_id']
    })

    return {
      accountId: payload['custom:account_id'],
      givenName: payload.given_name,
      familyName: payload.family_name,
      email: payload.email,
      picture: payload.picture,
      initials: payload.initials,
      phoneNumber: payload.phoneNumber,
      isEmployee: groups.includes('dpa:employee'),
      isFreelancer: groups.includes('dpa:freelancer'),
      isAdmin: groups.includes('dpa:administrator'),
      locality: payload['custom:locality'],
      groups: filterUserPoolFromGroup(groups)
    }
  }

  sentrySetUser(null)
}

const filterUserPoolFromGroup = (groups: string[]) => {
  const userPoolGroup = `${import.meta.env.VITE_COGNITO_USER_POOL_ID}_dpaid`
  const userPoolGroupAuth0 = `${
    import.meta.env.VITE_COGNITO_USER_POOL_ID
  }_dpaid-auth0`

  return groups.filter(
    group => group !== userPoolGroup && group !== userPoolGroupAuth0
  )
}

export const getIdTokenPayload = async (): Promise<
  CognitoIdToken['payload'] | undefined
> => {
  const idToken = await getIdToken()

  return idToken?.payload
}

export const getToken = async (): Promise<string | undefined> => {
  const idToken = await getIdToken()
  return idToken?.getJwtToken()
}

export const getIdToken = async (): Promise<CognitoIdToken | undefined> => {
  let cognitoUser: CognitoUser

  try {
    cognitoUser = await Auth.currentAuthenticatedUser()
  } catch {
    return
  }

  const session = cognitoUser.getSignInUserSession()

  return session?.getIdToken()
}
