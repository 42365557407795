import revive_payload_client_4sVQNw7RlN from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import apollo_1ILFkzpYSr from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/plugins/apollo.ts";
import auth_vT9JWWT9pN from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/plugins/auth.ts";
import sentry_3AyO8nEfhE from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/plugins/sentry.ts";
import vue_query_wrmMkNpEpe from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/plugins/vue-query.ts";
import vue_toastification_gZJw6dQQf2 from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/plugins/vue-toastification.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  apollo_1ILFkzpYSr,
  auth_vT9JWWT9pN,
  sentry_3AyO8nEfhE,
  vue_query_wrmMkNpEpe,
  vue_toastification_gZJw6dQQf2
]